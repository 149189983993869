import React from 'react'
import Layout from "../components/Layout"
import { graphql } from 'gatsby';
import { useTranslation} from 'gatsby-plugin-react-i18next';
import Loginstyles from '../styles/LoginStyles';
import { Container, Row, Col } from "reactstrap";
import { fetchUrl } from '../apiClient/baseApi';
const toastr = require('toastr')


export default function RequestPasswordReset () {
    const { t } = useTranslation();

    const WEB_URL = process.env.GATSBY_WEB_URL

    const handleSubmit = async function() {
        let data = { "email" : document.getElementById("email").value, "reset_url": WEB_URL + '/change-password' }
        await fetchUrl('post', '/auth/password/request', data).then(() => {
            toastr.success('An email with a link has been sent to you. Check your inbox including the spam folder')
        })
    }

  return (
  <Layout>
    <Loginstyles>
    <div className="login-screen siteStyl">
    <br />
    <Container className="bg-white" style={{width: "40%", padding: "50px"}}>
        <Row>
            <h4> {t('logon24')} </h4>
            <Col md="9">
                <input id="email" type="email" required placeholder={t('logon22')} />
            </Col>
            <Col md="3">
                <button className="ma-btn" onClick={handleSubmit}>{t('logon23')}</button>
            </Col>
        </Row>
    </Container>
    </div>
    <br />

    </Loginstyles>
  </Layout> 
   )
  }

  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["logon", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;